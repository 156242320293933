import React, {useEffect} from "react";

import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import SectionDivider from "../components/SectionDivider/SectionDivider";
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import SubscriptionPlan from "../components/SubscriptionPlan/SubscriptionPlan";
import ProductDescription from "../../shared/components/ProductDescription/ProductDescription";
import ContactUs from '../../Contact/components/ContactUs/ContactUs';


import manReadinfBannerImg from '../../utils/assets/home/Man-reading-a-magazine.png';
import readingMagazine from '../../utils/assets/home/reading-a-magazine.png';
import magazineClippings from '../../utils/assets/home/magazine-clippings.png';


import changeTitleIcon from '../../utils/assets/home/icons/change-titel-icon.png';
import giftAFriendIcon from '../../utils/assets/home/icons/gift-a-friend-icon.png';
import assistence247 from '../../utils/assets/home/icons/assistance247-icon.png';
import policyTermsIcon from '../../utils/assets/home/icons/policy-terms-icon.png';
import changeRefundIcon from '../../utils/assets/home/icons/change-refund-icon.png';
import moveIcon from '../../utils/assets/home/icons/icon-move-icon.png';
import BrandImgSection from "../components/BrandImgSection/BrandImgSection";
import Faq from "../../AboutUs/components/Faq/Faq";


const Home = props => {


     const bannerSectionInfo = 
         {
             cardTitle : "Get the magazines of your choice at the prices you want",
             cardBodyText: ``,
             cardButtonText : "sign up",
             lengendBackground : manReadinfBannerImg
         }

     

     const dividerInfo = {
            sectionName : "",
            icon1 : changeTitleIcon,
            description1 : "TITLE CHANGE IS EASY",
            icon2: giftAFriendIcon,
            description2 : "SHARE TITLES",
            icon3 : assistence247,
            description3 : "24/7/365 ASSISTANCE",
            icon4 : policyTermsIcon,
            description4 : "CRYSTAL-CLEAR POLICIES",
            icon5 : changeRefundIcon,
            description5:"EASY REFUND AND CANCEL",
            icon6 : moveIcon,
            description6 : "UPDATE YOUR ADDRESS"

        }

     const productDescription = {
        title :"Amazing prices on bestselling magazines", 
        img : readingMagazine

     }

     
     useEffect(() => {
        
        let sectionName = sessionStorage.getItem('sectionName');
        if (sectionName === null) sectionName = "home";
        const sectionElement = document.getElementById(sectionName);
        if (sectionElement) {
            const windowHeight = window.innerHeight;
            const docScrollTop = window.scrollY || document.documentElement.scrollTop;
            const sectionTop = sectionElement.getBoundingClientRect().top + docScrollTop;
            const centeredPosition = sectionTop - windowHeight / 2;
            const scrollToPosition = centeredPosition + 310;
            window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
        }
        
    }, []);

    return(            
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo}  sectionId="home"/>

            <NavegationInfo page="About us"/>


            <ProductDescription props={productDescription} sectionId="about-us"/>
            <BrandImgSection/>

            <SectionDivider props={dividerInfo}/>

            <section style={{backgroundImage: `url(${magazineClippings})`, padding : '1em'}} >
                <NavegationInfo page="Contact" />
                <ContactUs sectionId="contact-us"/>
            </section>

            <SectionInfo title={"Get a 45-day trial version for ONLY $3.29"} backgroundColor={'#f49e36'}/>

            <NavegationInfo page="Price" />

            <SubscriptionPlan sectionId="price"/> 

            {/* <NavegationInfo page="FQA"/> */}
 
            <Faq  sectionId="faq"/>

        </React.Fragment>
    );
};

export default Home;